const ServiceCard = ({image, title, subtitle}) => {
    return (
        <div className="card p-2 md:p-5 bg-white rounded-xl shadow-xl border border-gray-200">
            <img className="rounded-xl h-[210px] object-cover" src={image} alt={title}/>
            <h1 className="text-secondary font-semibold text-xl text-center mt-4">{title}</h1>
            <h4 className="text-primary font-semibold text-lg text-center mb-2">{subtitle}</h4>
        </div>
    )
}

export default ServiceCard
