import ServiceCard from "../cards/service"
import ServiceSlider from "../serviceSlider"

const Services = () => {
    return (
        <div className="h-full w-full flex flex-col gap-10 mb-16">
            <div className="w-full h-fit flex flex-col justify-center items-center px-10 pt-10">
                <h1 className="text-secondary text-3xl font-bold">What <span className="text-primary">Services</span> We Provide? </h1>
                <h6 className="text-secondary">Checkout the services we provide with dedicated people working on them.</h6>
            </div>
            <div>
                <div className="container grid grid-cols-1 md:grid-cols-3 gap-5 px-10">
                    <div className="md:col-span-2 md:row-span-2 col-span-1 row-span-1 border-2 border-primary rounded-xl">
                        <a 
                            href="pdf/Taj Al Reem.pdf"
                            download="taj-al-reem-services.pdf"
                            className="absolute z-10 flex gap-2 flex-row m-2 px-4 py-2 bg-primary text-secondary font-medium rounded-lg shadow-md">
                            <img className="w-[22px] h-[22px]" src="icons/download.svg"/>
                            <div>Download</div>
                        </a>
                        <ServiceSlider/>
                    </div>
                    <ServiceCard image={'/services/image0.png'} title={'Electric works'} subtitle={'أعمال الكهرباء'} />
                    <ServiceCard image={'/services/image1.png'} title={'Plumbing works'} subtitle={'أعمال السباكة'} />
                    <ServiceCard image={'/services/image2.png'} title={'Painting works'} subtitle={'أعمال الدهان'} />
                    <ServiceCard image={'/services/image3.png'} title={'Gypsum works'} subtitle={'أعمال الجبس'} />
                    <ServiceCard image={'/services/image4.png'} title={'AC works'} subtitle={'أعمال التكييف'} />
                    <ServiceCard image={'/services/image5.png'} title={'Tiles works'} subtitle={'أعمال البلاط'} />
                    <ServiceCard image={'/services/image6.png'} title={'Manpower supply'} subtitle={'توفير العمالة'} />
                    <ServiceCard image={'/services/image7.png'} title={'Design painting'} subtitle={'تصميم الدهان'} />
                    <ServiceCard image={'/services/image8.png'} title={'Spray painting'} subtitle={'دهان بالرش'} />
                </div>
            </div>
        </div>
    )
}

export default Services