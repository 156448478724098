import { useEffect } from "react"

const Header = () => {
    const openEmailWindow = (email) => {
        const mailtoLink = `mailto:${email}`
        window.location.href = mailtoLink
    }

    const handleWhatsAppClick = (number) => {
        const whatsappUrl = `https://wa.me/${'+971'+number}`
        window.open(whatsappUrl, '_blank')
    }

    useEffect(() => {
        let lastScrollTop = 0
        const header = document.getElementById("header")

        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop

            if (scrollTop > lastScrollTop && window.scrollY >= 190) {
                header.style.transform = "translateY(-100%)"
            } else {
                header.style.transform = "translateY(0)"
            }
            lastScrollTop = scrollTop
        }

        window.addEventListener("scroll", handleScroll)
        return () => {
           window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    return (
        <header id='header' className="flex flex-col justify-center items-center fixed w-full z-20 top-0 transition-transform duration-300">
            <div className=" h-fit md:h-[70px] flex flex-col md:flex-row w-full bg-secondary border-b-2 border-primary">
                <div className="w-full md:w-[300px] h-full bg-primary flex flex-col justify-center items-center md:items-end px-6 py-2 text-secondary">
                    <h1 className="text-2xl font-bold">TAJ AL REEM</h1>
                    <h6 className="text-xs font-semibold">Maintenance Works</h6>
                </div>
                <div className="w-full h-full flex flex-col md:flex-row justify-between gap-2 items-start md:items-center px-10 py-4 md:py-0 sticky top-0">
                    <div className="flex flex-col md:flex-row gap-2 md:gap-4 w-full">
                        <div className="flex justify-between cursor-pointer border border-primary rounded-xl md:w-fit w-full" onClick={() => handleWhatsAppClick('0507578413')}>
                            <div className="flex flex-row gap-2 justify-start items-center px-5 py-2">
                                <img className="w-[22px] h-[22px]" src="/icons/whatsapp.png" alt="phone-image"/> 
                                | <span className="text-sm font-bold text-white">0507578413</span>
                                <span className="text-sm font-bold text-white">HOSSAIN</span>
                            </div>
                            <div className="bg-primary rounded-r-xl px-5 py-2 w-[100px] text-secondary font-bold flex gap-1">
                                <img className="w-[22px] h-[22px]" src="/icons/call.svg" alt="phone-image"/> 
                                Call
                            </div>
                        </div>
                        <div className="flex justify-between cursor-pointer border border-primary rounded-xl md:w-fit w-full" onClick={() => handleWhatsAppClick('0503234639')}>
                            <div className="flex flex-row gap-2 justify-start items-center px-5 py-2">
                                <img className="w-[22px] h-[22px]" src="/icons/whatsapp.png" alt="phone-image"/> 
                                | <span className="text-sm font-bold text-white">0503234639</span>
                                <span className="text-sm font-bold text-white">RAFIK</span>
                            </div>
                            <div className="bg-primary rounded-r-xl px-5 py-2 w-[100px] text-secondary font-bold flex gap-1">
                                <img className="w-[22px] h-[22px]" src="/icons/call.svg" alt="phone-image"/> 
                                Call
                            </div>
                        </div>
                    </div>
                    {/* <div className="flex flex-row gap-2 justify-start items-center text-primary px-5 py-2 md:pr-10 pr-0 rounded-xl md:w-fit w-full" onClick={() => openEmailWindow('tajalreem1234@gmail.com')}>
                        <img className="w-[22px] h-[22px]" src="/icons/email.svg" alt="email-image"/>
                        <h1 className="text-sm font-bold text-white cursor-pointer">tajalreem1234@gmail.com</h1>
                    </div> */}
                </div>
            </div>
        </header>
    )
}

export default Header