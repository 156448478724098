import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';

import './styles.css';

import { Pagination, Autoplay, Navigation } from 'swiper/modules';

const ServiceSlider = () => {
  return (
    <div className='rounded-xl shadow-lg overflow-hidden'>
      <Swiper
        pagination={{
            dynamicBullets: true,
        }}
        autoplay={{
            delay: 2500,
            disableOnInteraction: false,
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
            <div>
                <img className='scale-[1.1]' src='pdf/Taj Al Reem_page-0001.jpg'/>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div>
                <img className='scale-[1.1]' src='pdf/Taj Al Reem_page-0002.jpg'/>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div>
                <img className='scale-[1.1]' src='pdf/Taj Al Reem_page-0003.jpg'/>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div>
                <img className='scale-[1.1]' src='pdf/Taj Al Reem_page-0004.jpg'/>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div>
                <img className='scale-[1.1]' src='pdf/Taj Al Reem_page-0005.jpg'/>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div>
                <img className='scale-[1.1]' src='pdf/Taj Al Reem_page-0006.jpg'/>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div>
                <img className='scale-[1.1]' src='pdf/Taj Al Reem_page-0007.jpg'/>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div>
                <img className='scale-[1.1]' src='pdf/Taj Al Reem_page-0008.jpg'/>
            </div>
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default ServiceSlider