const Contact = () => {
    const services = [
        'Electric works',
        'Plumbing works',
        'Painting works',
        'Gypsum works',
        'AC works',
        'Tiles works',
        'Manpower supply',
        'Design painting',
        'Spray painting',
    ]

    const handleWhatsAppClick = (number) => {
        const whatsappUrl = `https://wa.me/${'+971'+number}`
        window.open(whatsappUrl, '_blank')
    }

    return (
        <div className="w-full h-fit bg-darkBg border-t-2 border-b-2 border-primary py-10">
             <div className="w-full h-fit flex flex-col justify-center items-center pt-10 px-10">
                <h1 className="text-white text-3xl font-bold">Want to <span className="text-primary">Book</span> a Service? </h1>
                <h6 className="text-white">You can book a appointment by describing you're needs. You can just call/WhatsApp us directly!</h6>
            </div>
            <div className="container flex flex-col md:flex-row justify-between items-center gap-5 p-10">
                {/* <div className="h-fit w-full shadow-xl rounded-xl border border-gray-700 p-6 md:p-10 mb-[100px] md:mb-0">
                    <h1 className="text-2xl text-white font-semibold">Book Service</h1>
                    <h6 className="text-white font-normal mb-5"> Please enter your contact information and type of service you need. </h6>
                    <div className="flex flex-row gap-4">
                        <div className="flex-1">
                            <label className="text-white font-medium mb-2">Email</label>
                            <input className="input mb-4 px-5 py-3 w-full bg-secondary text-white rounded-md" type="text" placeholder="Enter your email"/>
                        </div>
                        <div className="flex-1">
                            <label className="text-white font-medium mb-2">Service</label>
                            <select className="select w-full px-5 bg-secondary">
                                {services.map((service) => (
                                <option key={service} value={service}>
                                    {service}
                                </option>))}
                            </select>
                        </div>
                    </div>
                    <div className="flex flex-row gap-4">
                        <div className="flex-1">
                            <label className="text-white font-medium mb-2">Phone</label>
                            <input className="input mb-4 px-5 py-3 w-full bg-secondary text-white rounded-md" type="text" placeholder="Enter your phone"/>
                        </div>
                        <div className="flex-1">
                            <label className="text-white font-medium mb-2">Location</label>
                            <input className="input mb-4 px-5 py-3 w-full bg-secondary text-white rounded-md" type="text" placeholder="Enter work location"/>
                        </div>
                    </div>
                    <div>
                        <label className="text-white font-medium mb-2">Description</label>
                        <textarea className="textarea bg-secondary px-5 py-3 rounded-md w-full min-h-[200px]">Give us a brief description...</textarea>
                        <button className="mt-5 bg-primary text-secondary rounded-md w-full md:w-[200px] py-3 font-medium">
                            SEND
                        </button>
                    </div>
                </div> */}
                <div className="w-full flex flex-col md:flex-row justify-center items-start gap-5 md:gap-20 md:ml-10 mb-[20px] mt-[20px] md:mt-0 md:mb-0">
                    <div className="flex flex-col md:items-end">
                        <h1 className="text-2xl text-white font-semibold"> Call or Whatsapp Us </h1>
                        <h6 className="text-white font-normal mb-5"> We are available on WhatsApp 24/7. Send us a message!</h6>
                        <div className="flex flex-col md:flex-row gap-2 md:gap-5">
                            <div className="flex justify-between cursor-pointer border border-primary rounded-xl md:w-fit w-full" onClick={() => handleWhatsAppClick('0507578413')}>
                                <div className="flex flex-row gap-2 justify-start items-center px-5 py-2">
                                    <img className="w-[22px] h-[22px]" src="/icons/whatsapp.png" alt="phone-image"/> 
                                    | <span className="text-sm font-bold text-white">0507578413</span>
                                    <span className="text-sm font-bold text-white">HOSSAIN</span>
                                </div>
                                <div className="bg-primary rounded-r-xl px-5 py-2 w-[100px] text-secondary font-bold flex gap-1">
                                    <img className="w-[22px] h-[22px]" src="/icons/call.svg" alt="phone-image"/> 
                                    Call
                                </div>
                            </div>
                            <div className="flex justify-between cursor-pointer border border-primary rounded-xl md:w-fit w-full" onClick={() => handleWhatsAppClick('0503234639')}>
                                <div className="flex flex-row gap-2 justify-start items-center px-5 py-2">
                                    <img className="w-[22px] h-[22px]" src="/icons/whatsapp.png" alt="phone-image"/> 
                                    | <span className="text-sm font-bold text-white">0503234639</span>
                                    <span className="text-sm font-bold text-white">RAFIK</span>
                                </div>
                                <div className="bg-primary rounded-r-xl px-5 py-2 w-[100px] text-secondary font-bold flex gap-1">
                                    <img className="w-[22px] h-[22px]" src="/icons/call.svg" alt="phone-image"/> 
                                    Call
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full h-[1px] my-4 md:my-0 md:w-[1px] rounded-full md:h-[150px] bg-primary"></div>
                    <div>
                        <h1 className="text-2xl text-white font-semibold"> Our Social Media </h1>
                        <h6 className="text-white font-normal mb-5"> Follow us on our socials and keep getting notified of our services!</h6>
                        <div className="flex flex-col gap-4 justify-center items-start">
                            <div className="flex flex-row gap-2 justify-center items-center">
                                <img className="w-[22px] h-[22px]" src="/icons/facebook.svg" alt="phone-image"/> 
                                | <span className="text-sm font-bold text-white">www.facebook.com/taj_al_reem</span>
                            </div>
                            {/* <div className="flex flex-row gap-2 justify-center items-center">
                                <img className="w-[22px] h-[22px]" src="/icons/twitter.svg" alt="phone-image"/> 
                                | <span className="text-sm font-bold text-white">www.x.com/tej_al_reem</span>
                            </div>
                            <div className="flex flex-row gap-2 justify-center items-center">
                                <img className="w-[22px] h-[22px]" src="/icons/insta.svg" alt="phone-image"/> 
                                | <span className="text-sm font-bold text-white">www.instagram.com/tej_al_reem</span>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )   
}

export default Contact