const Footer = () => {
    return (
        <div className="bg-[#111] w-full py-10">
            <div className="container px-10 flex flex-col md:flex-row justify-between items-end">
                <div>
                    <h1 className="text-2xl text-primary">TAJ AL REEM <span className="text-sm">Maintenance Works</span></h1>
                    <p className="text-sm max-w-[500px]">We do all kind of maintenance works such as electric work, plumbing, painting, sanitary work, tiles work, AC, gypsum works, manpower supply, design painting, spray painting.</p>
                </div>
            </div>
        </div>
    )
}

export default Footer