import Contact from './components/contact';
import Footer from './components/footer';
import Header from './components/header';
import Hero from './components/hero';
import Services from './components/services';


function App() {
  return (
    <div className="App">
        <Header />
        <Hero />
        <Services />
        <Contact />
        <Footer />
    </div>
  );
}

export default App;
