const Hero = () => {
    return (
        <div className="h-full w-full mb-[-50px] mt-[180px] md:mt-[70px]">
            <div className="bg-gradient-to-r from-[#FFE69A] to-white w-full h-[500px] md:h-full flex flex-row justify-between items-center border-b-2 border-primary py-10">
                <div className="flex-1 flex flex-col h-full gap-2 p-10 text-secondary ma">
                    <h1 className="text-5xl font-bold">All Kind of Maintenance Works</h1>
                    <p>We do all kind of maintenance works such as electric work, plumbing, painting, 
                    sanitary work, tiles work, AC, gypsum works, 
                    manpower supply, design painting, spray painting.</p>
                    {/* <button className="mt-5 bg-primary text-secondary rounded-md w-[200px] py-3 font-medium">
                        LEARN MORE
                    </button> */}
                </div>
                <div className="flex-1 hidden md:block">
                    <img src="/images/hero.png" alt="hero-image"/>
                </div>
            </div>
            <div className="w-full h-[300px] p-10 mt-[-150px] mb-[70px] md:mb-0">
                <div className="max-w-[450px] w-full h-fit bg-secondary rounded-2xl p-10 shadow-xl text-white font-medium border-2 border-primary steel-card">
                    <div className="w-full flex justify-start items-center"><p className="relative z-10">We are Available</p></div>
                    <div className="relative z-10 pb-5 md:pb-0 text-8xl font-bold text-white w-full flex justify-center items-center">24<span className="text-primary">/</span>7</div>
                    <div className="relative z-10 w-full flex justify-end items-center">for Your Service</div>
                </div>
            </div>
        </div>
    )
}

export default Hero